import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../components/mdx/page";
import { Accordion } from "../../components/widgets/accordion";
import { HelpPopup } from "../../components/widgets/help-popup";
import { Breadcrumbs } from "../../components/widgets/breadcrumbs";
import { FaFlask } from "react-icons/fa";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`In-Depth Articles`}</h1>
    <Breadcrumbs text="Help" to="/help/" mdxType="Breadcrumbs" />
    <div css={`display:flex;flex-wrap:wrap;`}>
      <div css={`
        display: none;
        @media ${props => props.theme.breakpoints.desktop} {
            display: inline-block;
            flex:1 0 200px;
            font-size:150px;
            color:${props => props.theme.colors.decorative};
        }
    `}>
        <div css={`width:calc(100% - 2 * ${props => props.theme.spacings.normal});`}>
          <FaFlask mdxType="FaFlask" />
        </div>
      </div>
      <div css={`flex:999 0 max(66%,300px);`}>
        <Accordion title="Investing 101" mdxType="Accordion">
          <ul>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/review-2023/"
              }}>{`2023 Review`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/live-trading-vs-backtests/"
              }}>{`Live Trading vs. Backtesting`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/market-liquidity/"
              }}>{`Market Liquidity`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/backtesting/"
              }}>{`How We Backtest`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/portfolio-wizard/"
              }}>{`How Our Portfolio Wizard Works`}</a></li>
          </ul>
        </Accordion>
        <Accordion title="Strategy Insights" mdxType="Accordion">
          <ul>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/momentum-mean-reversion/"
              }}>{`Momentum & Mean-Reversion`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/volatility-targeting/"
              }}>{`Volatility Targeting`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/market-vane/"
              }}>{`Determining the Market Regime`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/bonds-rising-yields/"
              }}>{`Bonds in a Rising Yield Environment`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/all-stars-portfolios/"
              }}>{`Why Meta-Portfolios Are Better`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/improving-momentum-strategies/"
              }}>{`Improving Momentum Strategies`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/low-noise-momentum/"
              }}>{`Low-Noise Momentum Strategies`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/leveraged-etf-portfolios/"
              }}>{`Leveraged ETF Portfolios`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/leveraged-etf-portfolios-2/"
              }}>{`2x Leveraged ETF Portfolios`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/tax-efficient-investing/"
              }}>{`Tax-Efficient Investing`}</a></li>
          </ul>
        </Accordion>
        <Accordion title="Book Reviews" mdxType="Accordion">
          <ul>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/30-minute-stock-trader/"
              }}>{`The 30-Minute Stock Trader`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/the-alpha-formula/"
              }}>{`The Alpha Formula`}</a></li>
          </ul>
        </Accordion>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      